import loadable from '@loadable/component';

const Modules = {
    call_to_action: loadable(() => import('./CallToAction')),
    '2_columns': loadable(() => import('./ColumnsTwo')),
    '3_columns': loadable(() => import('./ColumnsThree')),
    text_section: loadable(() => import('./TextSection')),
    newsletter: loadable(() => import('./Newsletter')),
    panels: loadable(() => import('./Panels')),
    discover: loadable(() => import('./Discover')),
    products_promotion_slider: loadable(() => import('./ProductsPromotionSlider')),
    products_promotion_columns: loadable(() => import('./ProductsPromotionColumns')),
    information: loadable(() => import('./Information')),
    articles_promotion: loadable(() => import('./ArticlesPromotion')),
    product_promotion: loadable(() => import('./ProductPromotion')),
    table: loadable(() => import('./Table')),
    article_hero: loadable(() => import('./ArticleHero')),
    html: loadable(() => import('./Html')),
    faq: loadable(() => import('./Faq')),
};

export default Modules;
