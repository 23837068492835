import React from 'react';
import PropTypes from 'prop-types';
import ErrorCatcher from '@sportson/core-web/components/ErrorCatcher';

/**
 * Accepts raw module data from the cms and tries to match the data to a key inside availableContent.
 * @param {object} availableContent - A object with avalible content as key and the corresponding Component as value.
 * @param {object} data - A object with block/module/layout data.
 * @param {...*} rest - All additional props will be forwarded to the Component.
 */

const ContentComponent = ({ availableContent, data, ...rest }) => {
    // Check if the cms content is missing a layout/module.
    if (!data?.layout) {
        console.warn(`ContentComponent name/layout could not be found not found.`);
        return null;
    }

    // Check if there is a built component for specified layout/module.
    if (!availableContent?.[data.layout]) {
        console.warn(`ContentComponent ${data.layout} not found.`);
        return null;
    }

    // Get the component and render it with all the data from the cms.
    const ContentComponent = availableContent[data.layout];

    return (
        <ErrorCatcher>
            <ContentComponent layout={data.layout} {...data} {...rest} />
        </ErrorCatcher>
    );
};

ContentComponent.propTypes = {
    availableContent: PropTypes.object.isRequired,
    data: PropTypes.object,
};

/**
 * Accepts raw module data from the cms and loops through it. It will pass all necessary data to the ContentComponent on each itteration.
 * @param {object} availableContent - A object with avalible content as key and the corresponding Component as value.
 * @param {object[]} data - An array of objects with block/module/layout data.
 * @param {...*} rest - All additional props will be forwarded to the Component.
 */

const ContentComponents = ({ availableContent, data, ...rest }) =>
    data?.map((item, index) => (
        <ContentComponent
            availableContent={availableContent}
            key={index}
            data={item}
            first={index === 0}
            last={index === data.length - 1}
            {...rest}
        />
    ));

ContentComponents.propTypes = {
    availableContent: PropTypes.object.isRequired,
    data: PropTypes.array,
};

export { ContentComponent, ContentComponents };
