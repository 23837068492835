import React from 'react';
import PropTypes from 'prop-types';
import { ContentComponent, ContentComponents } from '@sportson/core-web/libs/wordpress/utils/cmsComponentHandlers';

// Map of avaliable cmsModules and cmsLayouts.
const availableContent = {};

try {
    const themeModules = require('libs/wordpress/content/Modules').default;
    for (const i in themeModules) {
        const currentView = themeModules[i];
        if (availableContent[i] === undefined) {
            availableContent[i] = currentView;
        }
    }
} catch (e) {
    // Do nothing.
}

const CmsModules = ({ data, ...rest }) => {
    if (Array.isArray(data)) {
        return <ContentComponents data={data} availableContent={availableContent} {...rest} />;
    }
    return <ContentComponent data={data} availableContent={availableContent} {...rest} />;
};

CmsModules.propTypes = {
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

CmsModules.defaultProps = {
    data: {},
};

export default CmsModules;
